import dayjs from "dayjs";
import "dayjs/locale/de";
import { graphql } from "gatsby";
import { get } from "lodash-es";
import React from "react";
import { Layout, ScrollToTopBtn } from "../components";
import Footer from "../components/Footer";
import GoBack from "../components/GoBack";
import Header from "../components/Header";
import HeaderAlt from "../components/HeaderAlt";
import { Props } from "../models/props";
import { htmlToReact } from "../utils";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String, $strapiId: Int) {
    sitePage(path: { eq: $url }) {
      id
    }
    article(strapiId: { eq: $strapiId }) {
      id
      image_content {
        id
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;

const Post = ({ data, pageContext, ...props }: Props): JSX.Element => {
  const backgroundImage: GatsbyTypes.Maybe<GatsbyTypes.File> =
    data?.article?.image_content?.localFile;
  const title: GatsbyTypes.Maybe<string> = pageContext.frontmatter?.title;
  const subtitle: GatsbyTypes.Maybe<string> = pageContext.frontmatter?.subtitle;
  const date: GatsbyTypes.Maybe<string> = pageContext.frontmatter?.date;
  const html: GatsbyTypes.Maybe<string> = pageContext.html;
  // const hideHeader = pageContext.frontmatter.hide_header;
  return (
    <Layout {...props} site={pageContext.site} page={pageContext}>
      {get(pageContext, "frontmatter.hide_header", null) === true ? (
        <HeaderAlt {...props} />
      ) : (
        <Header
          site={pageContext.site}
          page={pageContext}
          image={backgroundImage}
        />
      )}
      <div id="content" className="site-content">
        <main id="main" className="site-main inner">
          <article className="post post-full">
            <header className="post-header">
              <h1 className="post-title">{title}</h1>
              <div className="post-meta">
                Veröffentlicht am{" "}
                <time
                  className="published"
                  dateTime={dayjs(date).locale("de").format("YYYY-MM-DD HH:mm")}
                >
                  {dayjs(date).locale("de").format("DD. MMMM YYYY")}
                </time>
              </div>
            </header>
            {subtitle && (
              <div className="post-subtitle">{htmlToReact(subtitle)}</div>
            )}
            <div className="post-content">{htmlToReact(html)}</div>
          </article>
        </main>
        <GoBack />
        <ScrollToTopBtn />
        <Footer site={pageContext.site} />
      </div>
    </Layout>
  );
};
export default Post;
